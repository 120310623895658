import subHeroSection from "../../components/subHeroSection/index.vue";
import articleSection from "../../components/articleSection/index.vue";

export default {
  name: "articlePage",
  components: {
    subHeroSection,
    articleSection
  },
};
