import navbarSection from "../../layouts/navbarSection/index.vue";
import footerSection from "../../layouts/footerSection/index.vue";

export default {
  name: "homePage",
  components: {
    navbarSection,
    footerSection,
  },
};
