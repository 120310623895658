import subHeroSection from "../../components/subHeroSection/index.vue";
import jobSection from "../../components/jobSection/index.vue";

export default {
  name: "jobPage",
  components: {
    subHeroSection,
    jobSection
  },
};
