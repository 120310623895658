import axios from "axios";
import "vue-select/dist/vue-select.css";

export default {
  name: "boostValoSection",
  data() {
    return {
      boostFromOptions: [
        "Iron", "Bronze", "Silver", "Gold", "Platinum", "Diamond", "Immortal"
      ],
      boostToOptions: [
        "Iron", "Bronze", "Silver", "Gold", "Platinum", "Diamond", "Immortal", "Radiant"
      ],

      paymentBtn: "opacity btn gradient color-white plr20 ptb15 mt5",
      opacityNone: "opacityNone",
      champName: [
        "Jett ",
        "Raze ",
        "Breach ",
        "Omen ",
        "Brimstone",
        "Phoenix ",
        "Sage ",
        "Sova ",
        "Viper ",
        "Cypher ",
        "Reyna ",
        "Killjoy ",
        "Skye ",
        "Yoru ",
        "Astra",
      ],
      percentagePriority: 0,
      percentageMain: 0,
      subDivision1: [
        {
          name: "Iron1",
          img: "rankBadges/TX_CompetitiveTier_Large_5.png",
        },
        {
          name: "Iron2",
          img: "rankBadges/TX_CompetitiveTier_Large_4.png",
        },
        {
          name: "Iron3",
          img: "rankBadges/TX_CompetitiveTier_Large_3.png",
        },
      ],
      subDivision2: [
        {
          name: "Iron1",
          img: "rankBadges/TX_CompetitiveTier_Large_5.png",
        },
        {
          name: "Iron2",
          img: "rankBadges/TX_CompetitiveTier_Large_4.png",
        },
        {
          name: "Iron3",
          img: "rankBadges/TX_CompetitiveTier_Large_3.png",
        },
      ],
      userDiv: {
        gameName: "valorant",
        user: null,
        from: "Iron",
        fromSubDivision: "Iron3",
        to: "Iron",
        toSubDivision: "Iron2",
        optionPriority: false,
        optionMain: false,
        champName: "",
        price: 0
      },
      imgDiv1: "rankBadges/TX_CompetitiveTier_Large_3.png",
      imgDiv2: "rankBadges/TX_CompetitiveTier_Large_4.png",

      token: null,
      card: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      secret_key: null,

      priceDivision: [
        {
          name: "Iron",
          divisionNumber: 0,
          price: [
            {
              name: "Iron2",
              price: 12,
              nbPrice: 0,
            },
            {
              name: "Iron1",
              price: 13,
              nbPrice: 1,
            },
            {
              name: "Iron",
              price: 133,
              nbPrice: 2,
            },
          ],
        },
        {
          name: "Bronze",
          divisionNumber: 1,
          price: [
            {
              name: "Bronze2",
              price: 20,
              nbPrice: 5,
            },
            {
              name: "Bronze1",
              price: 20,
              nbPrice: 6,
            },
            {
              name: "Bronze",
              price: 20,
              nbPrice: 7,
            },
          ],
        },
        {
          name: "Silver",
          price: [
            {
              name: "Silver2",
              price: 20,
              nbPrice: 9,
            },
            {
              name: "Silver1",
              price: 20,
              nbPrice: 10,
            },
            {
              name: "Silver",
              price: 20,
              nbPrice: 11,
            },
          ],
        },
        {
          name: "Gold",
          price: [
            {
              name: "Gold2",
              price: 20,
              nbPrice: 13,
            },
            {
              name: "Gold1",
              price: 20,
              nbPrice: 14,
            },
            {
              name: "Gold0",
              price: 20,
              nbPrice: 15,
            },
          ],
        },
        {
          name: "Platinum",
          price: [
            {
              name: "Platinum2",
              price: 20,
              nbPrice: 17,
            },
            {
              name: "Platinum1",
              price: 20,
              nbPrice: 18,
            },
            {
              name: "Platinum0",
              price: 20,
              nbPrice: 19,
            },
          ],
        },
        {
          name: "Diamond",
          price: [
            {
              name: "Diamond2",
              price: 20,
              nbPrice: 21,
            },
            {
              name: "Diamond1",
              price: 20,
              nbPrice: 22,
            },
            {
              name: "Diamond0",
              price: 20,
              nbPrice: 23,
            },
          ],
        },
        {
          name: "Immortal",
          price: [
            {
              name: "Immortal1",
              price: 12,
              nbPrice: 24,
            },
            {
              name: "Immortal",
              price: 20,
              nbPrice: 25,
            },
          ],
        },
        {
          name: "Radiant",
          price: [
            {
              name: "Radiant",
              price: 20,
              nbPrice: 27,
            },
          ],
        },
      ],
      // number
      toNumberDivision: 0,
      fromNumberDivision: 0,
      toNumberSubDivision: 0,
      fromNumberSubDivision: 0,
      PriceWithoutOption: 0,

      division: [
        {
          name: "Iron",
          img: "rankBadges/TX_CompetitiveTier_Large_3.png",
          divisionNumber: 0,
          subDivison: [
            {
              name: "Iron1",
              img: "rankBadges/TX_CompetitiveTier_Large_5.png",
              subDivisionNumber: 3,
            },
            {
              name: "Iron2",
              img: "rankBadges/TX_CompetitiveTier_Large_4.png",
              subDivisionNumber: 2,
            },
            {
              name: "Iron3",
              img: "rankBadges/TX_CompetitiveTier_Large_3.png",
              subDivisionNumber: 1,
            },
          ]
        },
        {
          name: "Bronze",
          img: "rankBadges/TX_CompetitiveTier_Large_6.png",
          divisionNumber: 1,
          subDivison: [
            {
              name: "Bronze1",
              img: "rankBadges/TX_CompetitiveTier_Large_8.png",
              subDivisionNumber: 7,
            },
            {
              name: "Bronze2",
              img: "rankBadges/TX_CompetitiveTier_Large_7.png",
              subDivisionNumber: 6,

            },
            {
              name: "Bronze3",
              img: "rankBadges/TX_CompetitiveTier_Large_6.png",
              subDivisionNumber: 5,
            },

          ]
        },
        {
          name: "Silver",
          img: "rankBadges/TX_CompetitiveTier_Large_9.png",
          divisionNumber: 2,
          subDivison: [
            {
              name: "Silver1",
              img: "rankBadges/TX_CompetitiveTier_Large_11.png",
              subDivisionNumber: 11,
            },
            {
              name: "Silver2",
              img: "rankBadges/TX_CompetitiveTier_Large_10.png",
              subDivisionNumber: 10,
            },
            {
              name: "Silver3",
              img: "rankBadges/TX_CompetitiveTier_Large_9.png",
              subDivisionNumber: 9,
            },
          ]
        },
        {
          name: "Gold",
          img: "rankBadges/TX_CompetitiveTier_Large_12.png",
          divisionNumber: 3,
          subDivison: [
            {
              name: "Gold1",
              img: "rankBadges/TX_CompetitiveTier_Large_14.png",
              subDivisionNumber: 15,

            },
            {
              name: "Gold2",
              subDivisionNumber: 14,
              img: "rankBadges/TX_CompetitiveTier_Large_13.png",
            },
            {
              name: "Gold3",
              img: "rankBadges/TX_CompetitiveTier_Large_12.png",
              subDivisionNumber: 13,

            },
          ]
        },
        {
          name: "Platinum",
          divisionNumber: 4,

          img: "rankBadges/TX_CompetitiveTier_Large_15.png",
          subDivison: [
            {
              subDivisionNumber: 18,
              name: "Platinum1",
              img: "rankBadges/TX_CompetitiveTier_Large_17.png",
            },
            {
              subDivisionNumber: 17,
              name: "Platinum2",
              img: "rankBadges/TX_CompetitiveTier_Large_16.png",
            },
            {
              subDivisionNumber: 16,
              name: "Platinum3",
              img: "rankBadges/TX_CompetitiveTier_Large_15.png",
            },
          ]
        },
        {
          name: "Diamond",
          divisionNumber: 5,

          img: "rankBadges/TX_CompetitiveTier_Large_18.png",
          subDivison: [
            {
              subDivisionNumber: 21,
              name: "Diamond1",
              img: "rankBadges/TX_CompetitiveTier_Large_20.png",
            },
            {
              subDivisionNumber: 20,
              name: "Diamond2",
              img: "rankBadges/TX_CompetitiveTier_Large_19.png",
            },
            {
              subDivisionNumber: 19,
              name: "Diamond3",
              img: "rankBadges/TX_CompetitiveTier_Large_18.png",
            },
          ]
        },
        {
          name: "Immortal",
          divisionNumber: 6,

          img: "rankBadges/TX_CompetitiveTier_Large_23.png",
          subDivison: [
            {
              subDivisionNumber: 22,
              name: "Immortal1",
              img: "rankBadges/TX_CompetitiveTier_Large_23.png",
            },
          ]
        },
        {
          name: "Radiant",
          divisionNumber: 7,
          img: "rankBadges/TX_CompetitiveTier_Large_24.png",
          subDivison: [
            {
              subDivisionNumber: 23,
              name: "Radiant",
              img: "rankBadges/TX_CompetitiveTier_Large_24.png",
            },
          ]
        },
      ]
    };
  },

  async created() {
    await this.optionPerc()
    await this.getOffre()
    await this.changeDivision()

  },
  methods: {
    getOffre() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/offreDetail/")
        .then((response) => {
          this.priceDivision = response.data.offreDetails[1].priceDivision;
          console.log("this.priceDivision", this.priceDivision)
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    preparePayment() {
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/stripe", {
          amount: this.userDiv.price, linkCancel: "https://eloboost.onrender.com/boost-valorant",
          name: "Order boost",
          message: "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page"
        }

        ).then((response) => {
          this.session_id = response.data.id
          this.createOrder()
          return this.$stripe.redirectToCheckout({ sessionId: this.session_id });

        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during payment",
            type: "error",
          });
        })
    },
    createOrder() {
      if (localStorage.getItem("token")) {
        var config = {
          headers: {
            authToken: localStorage.getItem("token"),
            refreshToken: localStorage.getItem("refreshToken")
          }
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/order",
          this.userDiv, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          this.$toast.open({
            message: "Thank you, we will back to you as soon as possible",
            type: "success",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during sending message",
            type: "error",
          });
        })
    },
    boostUser() {
      if (localStorage.getItem('userData')) {
        this.userDiv.user = localStorage.getItem('userData')
        if (this.userDiv.price == 0) {
          this.$toast.open({
            message: "Choice an offer",
            type: "info",
          });
        }
        else {
          this.opacityNone = "opacity"
          this.preparePayment()
        }
      } else {
        this.$toast.open({
          message: "You need to sign In",
          type: "error",
        });
      }
    },
    changeSubDivision() {
      this.division.forEach(element => {
        if (element.name == this.userDiv.to) {
          this.subDivision2 = element.subDivison
        }
      })
    },
    optionState(arg) {
      if (arg == 1) {
        this.userDiv.optionPriority = !this.userDiv.optionPriority
        if (this.userDiv.optionPriority) {
          this.userDiv.price = this.userDiv.price + Math.round(((this.PriceWithoutOption * this.percentagePriority) / 100))
        } else {
          this.userDiv.price = this.userDiv.price - Math.round(((this.PriceWithoutOption * this.percentagePriority) / 100))
        }
      } else {
        this.userDiv.optionMain = !this.userDiv.optionMain
        if (this.userDiv.optionMain) {
          this.userDiv.price = this.userDiv.price + Math.round(((this.PriceWithoutOption * this.percentageMain) / 100))
        } else {
          this.userDiv.price = this.userDiv.price - Math.round(((this.PriceWithoutOption * this.percentageMain) / 100))
        }
      }
    },
    optionPerc() {
      axios
        .get(
          "https://eloboost-tn.herokuapp.com/api/gamesPriceOptions/",

        )
        .then((response) => {
          console.log(response)
          this.percentagePriority = response.data.gamesPriceOptions[0].priceDivisionOption.o1;
          this.percentageMain = response.data.gamesPriceOptions[0].priceDivisionOption.o2;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error please verifiy the current rank or the desired rank",
            type: "error",
          });
        })
    },
    async changeDivision(arg) {
      this.paymentBtn = "opacity btn gradient color-white plr20 ptb15 mt5"
      this.opacityNone = "opacityNone"
      await this.division.forEach(element => {
        if (element.name == this.userDiv.from) {
          this.fromNumberDivision = element.divisionNumber
          this.subDivision1 = element.subDivison
          for (let index = 0; index < element.subDivison.length; index++) {
            if (element.subDivison[index].name == this.userDiv.fromSubDivision) {
              this.fromNumberSubDivision = element.subDivison[index].subDivisionNumber
              this.imgDiv1 = element.subDivison[index].img
            }
          }
        }
        if (element.name == this.userDiv.to) {
          this.toNumberDivision = element.divisionNumber
          this.subDivision2 = element.subDivison
          for (let index = 0; index < element.subDivison.length; index++) {
            if (element.subDivison[index].name == this.userDiv.toSubDivision) {
              this.toNumberSubDivision = element.subDivison[index].subDivisionNumber
              this.imgDiv2 = element.subDivison[index].img
            }
          }
        }
      });
      //price
      this.userDiv.price = 0;
      console.log(
        this.fromNumberDivision,
        this.toNumberDivision,
        this.fromNumberSubDivision,
        this.toNumberSubDivision,
      );


      var toNb = this.toNumberSubDivision
      var fromNb = this.fromNumberSubDivision

      console.log("obj", this.priceDivision, fromNb, toNb)
      if (this.fromNumberDivision > this.toNumberDivision) {
        this.userDiv.price = 0;
        this.userDiv.to = this.userDiv.from
        this.changeSubDivision()
      } else if ((this.fromNumberDivision == this.toNumberDivision) && (this.fromNumberSubDivision > this.toNumberSubDivision)) {
        this.userDiv.fromSubDivision = this.userDiv.toSubDivision
      } else if (this.fromNumberSubDivision == this.toNumberSubDivision) {
        this.$toast.open({
          message: "Please change the desired division",
          type: "info",
        })
      } else {
        var index = this.fromNumberDivision;

        var nb = 0
        do {
          nb = toNb - fromNb
          if (nb > this.priceDivision[index].price.length) {
            this.userDiv.price = this.userDiv.price + this.countAllSubDivision(this.priceDivision[index].price)
            fromNb = fromNb + this.priceDivision[index].price.length
          } else {
            this.userDiv.price = this.userDiv.price + this.countLimitedDivision(this.priceDivision[index].price, nb)
            fromNb = toNb + 1
          }
          index = index + 1
        } while (fromNb < toNb)
      }
      this.PriceWithoutOption = this.userDiv.price
    },
    countAllSubDivision(obj) {
      var res = 0
      obj.forEach(element => {
        res = res + element.price
      });
      return res
    },
    countLimitedDivision(obj, to) {
      var res = 0
      for (let index = 0; index < to; index++) {
        console.log("obj", obj[index])
        res = res + obj[index].price;
      }
      return res
    }
  }
};