export default {
  name: "serviceSection",
  components: {},
  data() {
    return {};
  },
  created() {},

  methods: {},
};
