import subHeroSection from "../../components/subHeroSection/index.vue";
import signInSection from "../../components/signInSection/index.vue";

export default {
  name: "signUpPage",
  components: {
    subHeroSection,
    signInSection
  },
};
