import axios from "axios";
// import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
  name: "boostSection",
  // components: {
  //   StripeElementCard,
  // },
  data() {
    return {
      boostFromOptions: [
        "Iron", "Bronze", "Silver", "Gold", "Platinum", "Diamond", "Master", "Grandmaster"
      ],
      boostToOptions: [
        "Iron", "Bronze", "Silver", "Gold", "Platinum", "Diamond", "Master", "Grandmaster", "Challenger"
      ],
      paymentBtn: "opacity btn gradient color-white plr20 ptb15 mt5",
      opacityNone: "opacityNone",
      percentagePriority: 0,
      percentageMain: 0,
      subDivision1: [
        {
          name: "Iron1",
          img: "rankBadges/Season_2019_-_Iron_1.png",
        },
        {
          name: "Iron2",
          img: "rankBadges/Season_2019_-_Iron_2.png",
        },
        {
          name: "Iron3",
          img: "rankBadges/Season_2019_-_Iron_3.png",
        },
        {
          name: "Iron4",
          img: "rankBadges/Season_2019_-_Iron_4.png",
        }
      ],
      subDivision2: [
        {
          name: "Iron1",
          img: "rankBadges/Season_2019_-_Iron_1.png",
        },
        {
          name: "Iron2",
          img: "rankBadges/Season_2019_-_Iron_2.png",
        },
        {
          name: "Iron3",
          img: "rankBadges/Season_2019_-_Iron_3.png",
        },
        {
          name: "Iron4",
          img: "rankBadges/Season_2019_-_Iron_4.png",
        }
      ],
      userDiv: {
        gameName: "tft",
        user: null,
        from: "Iron",
        fromSubDivision: "Iron4",
        to: "Iron",
        toSubDivision: "Iron3",
        optionPriority: false,
        optionMain: false,
        price: 0
      },
      imgDiv1: "rankBadges/Season_2019_-_Iron_4.png",
      imgDiv2: "rankBadges/Season_2019_-_Iron_3.png",

      token: null,
      card: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      secret_key: null,

      priceDivision: [
        {
          name: "Iron",
          divisionNumber: 0,
          price: [
            {
              name: "Iron4",
              price: 12,
              nbPrice: 0
            },
            {
              name: "Iron3",
              price: 10,
              nbPrice: 1
            },
            {
              name: "Iron2",
              price: 10,
              nbPrice: 2
            },
            {
              name: "Iron",
              price: 10,
              nbPrice: 3
            }
          ]
        },
        {
          name: "Bronze",
          divisionNumber: 1,
          price: [
            {
              name: "Bronze4",
              price: 12,
              nbPrice: 4

            },
            {
              name: "Bronze3",
              price: 20,
              nbPrice: 5
            },
            {
              name: "Bronze2",
              price: 20,
              nbPrice: 6

            },
            {
              name: "Bronze",
              price: 20,
              nbPrice: 7
            }
          ]
        },
        {
          name: "Silver",
          divisionNumber: 2,

          price: [
            {
              name: "Silver3",
              price: 12,
              nbPrice: 8
            },
            {
              name: "Silver2",
              price: 20,
              nbPrice: 9
            },
            {
              name: "Silver1",
              price: 20,
              nbPrice: 10
            },
            {
              name: "Silver0",
              price: 20,
              nbPrice: 11
            }
          ]
        },
        {
          name: "Gold",
          divisionNumber: 3,

          price: [
            {
              name: "Gold3",
              price: 12,
              nbPrice: 12

            },
            {
              name: "Gold2",
              price: 20,
              nbPrice: 13

            },
            {
              name: "Gold1",
              price: 20,
              nbPrice: 14
            },
            {
              name: "Gold0",
              price: 20,
              nbPrice: 15
            }
          ]
        },
        {
          name: "Platinum",
          price: [
            {
              name: "Platinum3",
              price: 12,
              nbPrice: 16

            },
            {
              name: "Platinum2",
              price: 20,
              nbPrice: 17

            },
            {
              name: "Platinum1",
              price: 20,
              nbPrice: 18

            },
            {
              name: "Platinum0",
              price: 20,
              nbPrice: 19

            }
          ]
        },
        {
          name: "Diamond",
          price: [
            {
              name: "Diamond3",
              price: 12,
              nbPrice: 20

            },
            {
              name: "Diamond2",
              price: 20,
              nbPrice: 21
            },
            {
              name: "Diamond1",
              price: 20,
              nbPrice: 22
            },
            {
              name: "Diamond0",
              price: 20,
              nbPrice: 23
            }
          ]
        },
        {
          name: "Master",
          price: [
            {
              name: "Master",
              price: 12,
              nbPrice: 24
            },
            {
              name: "Master0",
              price: 20,
              nbPrice: 25
            },
          ]
        },
        {
          name: "Grandmaster",
          price: [
            {
              name: "Grandmaster",
              price: 12,
              nbPrice: 26
            },
            {
              name: "Grandmaster0",
              price: 20,
              nbPrice: 27
            },
          ]
        },
        {
          name: "Challenger",
          price: [
            {
              name: "Challenger",
              price: 100,
              nbPrice: 28
            },
          ]
        },
      ],
      // number
      toNumberDivision: 0,
      fromNumberDivision: 0,
      toNumberSubDivision: 0,
      fromNumberSubDivision: 0,
      PriceWithoutOption: 0,
      division: [
        {
          name: "Iron",
          img: "rankBadges/Season_2019_-_Iron_4.png",
          divisionNumber: 0,
          subDivison: [
            {
              name: "Iron1",
              img: "rankBadges/Season_2019_-_Iron_1.png",
              subDivisionNumber: 3,
            },
            {
              name: "Iron2",
              img: "rankBadges/Season_2019_-_Iron_2.png",
              subDivisionNumber: 2,
            },
            {
              name: "Iron3",
              img: "rankBadges/Season_2019_-_Iron_3.png",
              subDivisionNumber: 1,
            },
            {
              name: "Iron4",
              img: "rankBadges/Season_2019_-_Iron_4.png",
              subDivisionNumber: 0,
            }
          ]
        },
        {
          name: "Bronze",
          img: "rankBadges/Season_2019_-_Bronze_4.png",
          divisionNumber: 1,
          subDivison: [
            {
              name: "Bronze1",
              img: "rankBadges/Season_2019_-_Bronze_1.png",
              subDivisionNumber: 7,
            },
            {
              name: "Bronze2",
              img: "rankBadges/Season_2019_-_Bronze_2.png",
              subDivisionNumber: 6,

            },
            {
              name: "Bronze3",
              img: "rankBadges/Season_2019_-_Bronze_3.png",
              subDivisionNumber: 5,
            },
            {
              name: "Bronze4",
              img: "rankBadges/Season_2019_-_Bronze_4.png",
              subDivisionNumber: 4,
            }
          ]
        },
        {
          name: "Silver",
          img: "rankBadges/Season_2019_-_Silver_4.png",
          divisionNumber: 2,
          subDivison: [
            {
              name: "Silver1",
              img: "rankBadges/Season_2019_-_Silver_1.png",
              subDivisionNumber: 11,
            },
            {
              name: "Silver2",
              img: "rankBadges/Season_2019_-_Silver_2.png",
              subDivisionNumber: 10,
            },
            {
              name: "Silver3",
              img: "rankBadges/Season_2019_-_Silver_3.png",
              subDivisionNumber: 9,
            },
            {
              name: "Silver4",
              img: "rankBadges/Season_2019_-_Silver_4.png",
              subDivisionNumber: 8,
            }
          ]
        },
        {
          name: "Gold",
          img: "rankBadges/Season_2019_-_Gold_4.png",
          divisionNumber: 3,
          subDivison: [
            {
              name: "Gold1",
              img: "rankBadges/Season_2019_-_Gold_1.png",
              subDivisionNumber: 15,

            },
            {
              name: "Gold2",
              subDivisionNumber: 14,
              img: "rankBadges/Season_2019_-_Gold_2.png",
            },
            {
              name: "Gold3",
              img: "rankBadges/Season_2019_-_Gold_3.png",
              subDivisionNumber: 13,

            },
            {
              name: "Gold4",
              subDivisionNumber: 12,
              img: "rankBadges/Season_2019_-_Gold_4.png",
            }
          ]
        },
        {
          name: "Platinum",
          img: "rankBadges/Season_2019_-_Platinum_4.png",
          divisionNumber: 4,
          subDivison: [
            {
              name: "Platinum1",
              img: "rankBadges/Season_2019_-_Platinum_1.png",
              subDivisionNumber: 19,

            },
            {
              name: "Platinum2",
              img: "rankBadges/Season_2019_-_Platinum_2.png",
              subDivisionNumber: 18,

            },
            {
              name: "Platinum3",
              img: "rankBadges/Season_2019_-_Platinum_3.png",
              subDivisionNumber: 17,

            },
            {
              name: "Platinum4",
              img: "rankBadges/Season_2019_-_Platinum_4.png",
              subDivisionNumber: 16,

            }
          ]
        },
        {
          name: "Diamond",
          img: "rankBadges/Season_2019_-_Diamond_4.png",
          divisionNumber: 5,

          subDivison: [
            {
              name: "Diamond1",
              img: "rankBadges/Season_2019_-_Diamond_1.png",
              subDivisionNumber: 23,

            },
            {
              name: "Diamond2",
              img: "rankBadges/Season_2019_-_Diamond_2.png",
              subDivisionNumber: 22,

            },
            {
              name: "Diamond3",
              img: "rankBadges/Season_2019_-_Diamond_3.png",
              subDivisionNumber: 21,

            },
            {
              name: "Diamond4",
              img: "rankBadges/Season_2019_-_Diamond_4.png",
              subDivisionNumber: 20,

            }
          ]
        },
        {
          name: "Master",
          img: "rankBadges/Season_2019_-_Master_1.png",
          divisionNumber: 6,

          subDivison: [
            {
              name: "Master",
              img: "rankBadges/Season_2019_-_Master_1.png",
              subDivisionNumber: 24,

            },
          ]
        },
        {
          name: "Grandmaster",
          img: "rankBadges/Season_2019_-_Grandmaster_1.png",
          divisionNumber: 7,

          subDivison: [
            {
              name: "Grandmaster",
              img: "rankBadges/Season_2019_-_Grandmaster_1.png",
              subDivisionNumber: 25,

            },
          ]
        },
        {
          name: "Challenger",
          img: "rankBadges/Season_2019_-_Challenger_1.png",
          divisionNumber: 8,

          subDivison: [
            {
              name: "Challenger",
              img: "rankBadges/Season_2019_-_Challenger_1.png",
              subDivisionNumber: 26,

            },
          ]
        },
      ]
    };
  },
  async created() {
    await this.optionPerc()
    await this.getOffre()
    await this.changeDivision()
  },
  methods: {
    preparePayment() {
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/stripe", {
          amount: this.userDiv.price, linkCancel: "https://eloboost.onrender.com/boost-tft",
          name: "Order boost",
          message: "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page"
        }

        ).then((response) => {
          this.createOrder()
          this.session_id = response.data.id
          return this.$stripe.redirectToCheckout({ sessionId: this.session_id });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during payment",
            type: "error",
          });
        })
    },
    createOrder() {
      if (localStorage.getItem("token")) {
        var config = {
          headers: {
            authToken: localStorage.getItem("token"),
            refreshToken: localStorage.getItem("refreshToken")
          }
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/order",
          this.userDiv, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          this.$toast.open({
            message: "Thank you, we will back to you as soon as possible",
            type: "success",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during sending message",
            type: "error",
          });
        })
    },
    boostUser() {
      if (localStorage.getItem('userData')) {
        this.userDiv.user = localStorage.getItem('userData')
        if (this.userDiv.price == 0) {
          this.$toast.open({
            message: "Choice an offer",
            type: "info",
          });
        }
        else {
          this.opacityNone = "opacity"
          this.preparePayment()
        }
      } else {
        this.$toast.open({
          message: "You need to sign In",
          type: "error",
        });
      }
    },
    // boostUser() {
    //   if (localStorage.getItem('userData')) {
    //     this.userDiv.user = localStorage.getItem('userData')
    //     axios
    //       .post(
    //         "https://eloboost-tn.herokuapp.com/api/order",
    //         this.userDiv
    //       )
    //       .then((response) => {
    //         console.log("rate", response)
    //         this.$toast.open({
    //           message: "Thank you, we will back to you as soon as possible",
    //           type: "success",
    //         });
    //         this.preparePayment()
    //       })
    //       .catch((error) => {
    //         console.error("There was an error!", error);
    //         this.$toast.open({
    //           message: "Error during sending message",
    //           type: "error",
    //         });
    //       })
    //   } else {
    //     this.$toast.open({
    //       message: "You need to sign In",
    //       type: "info",
    //     });
    //   }
    // },

    getOffre() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/offreDetail/")
        .then((response) => {
          this.priceDivision = response.data.offreDetails[1].priceDivision;
          console.log(this.priceDivision)
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    // async createToken() {
    //   const { token, error } = await this.$stripe.createToken(this.card);
    //   if (error) {
    //     // handle error here
    //     document.getElementById('card-error').innerHTML = error.message;
    //     document.getElementById('card-success').innerHTML = ""
    //     return;
    //   }
    //   document.getElementById('card-error').innerHTML = ""
    //   document.getElementById('card-success').innerHTML = "Valid card";
    //   console.log(token);
    //   // handle the token
    //   // send it to your server
    // },
    // preparePayment() {
    //   axios
    //     .post(
    //       "https://eloboost-tn.herokuapp.com/api/user/stripe", { amount: this.userDiv.price }

    //     ).then((response) => {
    //       console.log("stripe", response)
    //       this.secret_key = response.data.user.client_secret.toString()
    //       console.log(this.secret_key)
    //     })
    //     .catch((error) => {
    //       console.error("There was an error!", error);
    //       this.$toast.open({
    //         message: "Error during payment",
    //         type: "error",
    //       });
    //     })
    // },
    // async paymentOffre() {
    //   this.paymentBtn = "opacityNone btn gradient color-white plr20 ptb15 mt5"
    //   const result = await this.$stripe.confirmCardPayment(this.secret_key, {
    //     payment_method: {
    //       card: this.card,
    //       billing_details: {
    //         name: localStorage.getItem('userData'),
    //       }
    //     }
    //   })
    //   console.log(result)
    //   if (result) {
    //     axios
    //       .post(
    //         "https://eloboost-tn.herokuapp.com/api/order",
    //         this.userDiv
    //       )
    //       .then((response) => {
    //         console.log(response)
    //         this.$toast.open({
    //           message: "Thank you, We will back to you as soon as possible",
    //           type: "info",
    //         });
    //         axios
    //           .post(
    //             "https://eloboost-tn.herokuapp.com/api/payment/", { user: localStorage.getItem('userData'), amount: this.userDiv.price }

    //           ).then((response) => {
    //             console.log("stripe", response)
    //           })
    //           .catch((error) => {
    //             console.error("There was an error!", error);
    //           })
    //       })
    //       .catch((error) => {
    //         console.error("There was an error!", error);
    //         this.$toast.open({
    //           message: "Error please verifiy the current rank or the desired rank",
    //           type: "error",
    //         });
    //       })


    //   }

    // },
    optionPerc() {
      axios
        .get(
          "https://eloboost-tn.herokuapp.com/api/gamesPriceOptions/",

        )
        .then((response) => {
          console.log(response)
          this.percentagePriority = response.data.gamesPriceOptions[0].priceDivisionOption.o1;
          this.percentageMain = response.data.gamesPriceOptions[0].priceDivisionOption.o2;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error please verifiy the current rank or the desired rank",
            type: "error",
          });
        })
    },

    optionState(arg) {
      this.paymentBtn = "opacity btn gradient color-white plr20 ptb15 mt5"
      this.opacityNone = "opacityNone"
      if (arg == 1) {
        this.userDiv.optionPriority = !this.userDiv.optionPriority
        if (this.userDiv.optionPriority) {
          this.userDiv.price = this.userDiv.price + Math.round(((this.PriceWithoutOption * this.percentagePriority) / 100))
        } else {
          this.userDiv.price = this.userDiv.price - Math.round(((this.PriceWithoutOption * this.percentagePriority) / 100))
        }
      } else {
        this.userDiv.optionMain = !this.userDiv.optionMain
        if (this.userDiv.optionMain) {
          this.userDiv.price = this.userDiv.price + Math.round(((this.PriceWithoutOption * this.percentageMain) / 100))
        } else {
          this.userDiv.price = this.userDiv.price - Math.round(((this.PriceWithoutOption * this.percentageMain) / 100))
        }
      }
    },

    changeSubDivision() {
      this.division.forEach(element => {
        if (element.name == this.userDiv.to) {
          this.subDivision2 = element.subDivison
        }
      })
    },

    async changeDivision(arg) {
      this.paymentBtn = "opacity btn gradient color-white plr20 ptb15 mt5"
      this.opacityNone = "opacityNone"
      await this.division.forEach(element => {
        if (element.name == this.userDiv.from) {
          this.fromNumberDivision = element.divisionNumber
          this.subDivision1 = element.subDivison
          for (let index = 0; index < element.subDivison.length; index++) {
            if (element.subDivison[index].name == this.userDiv.fromSubDivision) {
              this.fromNumberSubDivision = element.subDivison[index].subDivisionNumber
              this.imgDiv1 = element.subDivison[index].img
            }
          }
        }
        if (element.name == this.userDiv.to) {
          this.toNumberDivision = element.divisionNumber
          this.subDivision2 = element.subDivison
          for (let index = 0; index < element.subDivison.length; index++) {
            if (element.subDivison[index].name == this.userDiv.toSubDivision) {
              this.toNumberSubDivision = element.subDivison[index].subDivisionNumber
              this.imgDiv2 = element.subDivison[index].img
            }
          }
        }
      });
      //price
      this.userDiv.price = 0;
      console.log(
        this.fromNumberDivision,
        this.toNumberDivision,
        this.fromNumberSubDivision,
        this.toNumberSubDivision,
      );
      var toNb = this.toNumberSubDivision
      var fromNb = this.fromNumberSubDivision
      console.log("obj", this.priceDivision, fromNb, toNb)
      if (this.fromNumberDivision > this.toNumberDivision) {
        this.userDiv.price = 0;
        this.userDiv.to = this.userDiv.from
        this.changeSubDivision()
      } else if ((this.fromNumberDivision == this.toNumberDivision) && (this.fromNumberSubDivision > this.toNumberSubDivision)) {
        this.userDiv.fromSubDivision = this.userDiv.toSubDivision
      } else if (this.fromNumberSubDivision == this.toNumberSubDivision) {
        this.$toast.open({
          message: "Please change the desired division",
          type: "info",
        })
      } else {
        var index = this.fromNumberDivision;

        var nb = 0
        do {
          nb = toNb - fromNb
          if (nb > this.priceDivision[index].price.length) {
            this.userDiv.price = this.userDiv.price + this.countAllSubDivision(this.priceDivision[index].price)
            fromNb = fromNb + this.priceDivision[index].price.length
          } else {
            this.userDiv.price = this.userDiv.price + this.countLimitedDivision(this.priceDivision[index].price, nb)
            fromNb = toNb + 1
          }
          index = index + 1
        } while (fromNb < toNb)
      }
      this.PriceWithoutOption = this.userDiv.price
    },
    countAllSubDivision(obj) {
      var res = 0
      obj.forEach(element => {
        res = res + element.price
      });
      return res
    },
    countLimitedDivision(obj, to) {
      var res = 0
      for (let index = 0; index < to; index++) {
        console.log("obj", obj[index])
        res = res + obj[index].price;
      }
      return res
    }
  }
};
