import subHeroSection from "../../components/subHeroSection/index.vue";
import boostSection from "../../components/boostLolSection/index.vue";

export default {
  name: "boostPage",
  components: {
    subHeroSection,
    boostSection
  },
};
