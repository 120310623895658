import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueAnalytics from 'vue-analytics'
import { StripePlugin } from '@vue-stripe/vue-stripe';
import VueFbCustomerChat from 'vue-fb-customer-chat'
import rate from 'vue-rate'
import 'vue-rate/dist/vue-rate.css'
import vSelect from 'vue-select'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles

Vue.component('v-select', vSelect)
const options = {
  pk: "pk_test_51IifTsHEnh9El5KsRcLrbW0bYoiHkmGM87SZSredPsTEeoQ4tmlmQlzdorO588pwWU5yjKEsfULH470BATJ0Lsqh00YeHILRjy",
  // stripeAccount: process.env.STRIPE_ACCOUNT,
  // apiVersion: process.env.API_VERSION,
  // locale: process.env.LOCALE,
};
Vue.use(rate)
Vue.use(Vuesax)

Vue.use(StripePlugin, options);
Vue.use(VueFbCustomerChat, {
  page_id: "100321442153247", //  change 'null' to your Facebook Page ID,
  theme_color: '#ed302a', // theme color in HEX
  locale: 'en_US', // default 'en_US'
})

Vue.use(VueToast);

Vue.config.productionTip = false;
Vue.use(VueAnalytics, {
  id: 'G-0MZ0ED3VB1'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
