import heroSection from "../../components/heroSection/index.vue";
import serviceSection from "../../components/serviceSection/index.vue";
import blogSection from "../../components/blogSection/index.vue";
import coachSection from "../../components/coachSection/index.vue";
import newGamesSection from "../../components/newGamesSection/index.vue";
import howToUseSection from "../../components/howToUseSection/index.vue";


export default {
  name: "homePage",
  components: {
    heroSection,
    serviceSection,
    blogSection,
    coachSection,
    newGamesSection,
    howToUseSection
  },
};
