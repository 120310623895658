import axios from "axios";

export default {
  name: "signUpSection",
  data() {
    return {
      passwordInput1: "password",
      passwordInput2: "password",
      iconPassword1: "fa fa-eye",
      iconPassword2: "fa fa-eye",
      confirmPassword: "",
      errorMesssage: "",
      errorPasswordMesssage: "",
      user: {
        userName: "",
        email: "",
        // phoneNumber: "",
        password: ""
      }
    };
  },
  methods: {
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)) {
        this.errorMesssage = '';
      } else {
        this.errorMesssage = 'Please enter a valid email address';
      }
      console.log(this.errorMesssage);
    },
    switchVisibility(type) {
      if (type == 1) {
        this.passwordInput1 = this.passwordInput1 === "password" ? "text" : "password";
        this.iconPassword1 = this.iconPassword1 === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye"
      } else {
        this.passwordInput2 = this.passwordInput2 === "password" ? "text" : "password";
        this.iconPassword2 = this.iconPassword2 === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye"
      }
    },
    signUpUser(e) {
      e.preventDefault()
      console.log(this.user)
      if(this.confirmPassword != this.user.password) {
        axios
          .post(
            "https://eloboost-tn.herokuapp.com/api/user/signup",
            this.user
          )
          .then((response) => {
            console.log(response)
            this.$toast.open({
              message: "Your account is created successfully, please verify your account",
              type: "info",
            });
            this.confirmPassword = "";
            this.user.userName = ""
            this.user.email = ""
            this.user.password = ""
            this.$router.replace("/");
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.$toast.open({
              message: "Error during registration, please verify your email or your password",
              type: "error",
            });
          })
      }else{
        this.errorPasswordMesssage = "Password and confirm password not similar"
      }
    }
}
}
