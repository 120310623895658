import axios from "axios";

export default {
  name: "contactSection",
  data() {
    return {
      contact: {
        fullName: "",
        email: "",
        subject: "",
        message: ""
      }
    };
  },
  methods: {
    sendContact(e) {
      e.preventDefault();
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/contact",
          this.contact
        )
        .then((response) => {
          console.log(response)
          this.$toast.open({
            message: "Your message have been sent",
            type: "info",
          });
          this.contact = {
            fullName: "",
            email: "",
            subject: "",
            message: ""
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during sending message",
            type: "error",
          });
        })
    }
  }
};
