import subHeroSection from "../../components/subHeroSection/index.vue";
import settingsSection from "../../components/settingsSection/index.vue";

export default {
  name: "settingsPage",
  components: {
    subHeroSection,
    settingsSection
  },
};
