import subHeroSection from "../../components/subHeroSection/index.vue";
import boosterSection from "../../components/boosterSection/index.vue";

export default {
  name: "boosterPage",
  components: {
    subHeroSection,
    boosterSection
  },
};
