import axios from "axios";

export default {
  name: "newGamesSection",
  components: {},
  data() {
    return {
      newGameData: []
    };
  },
  created() {
    axios
      .get(
        "https://eloboost-tn.herokuapp.com/api/offre"
      )
      .then((response) => {
        if (response.data.offres.length > 3) {
          this.newGameData.push(response.data.offres[response.data.offres.length - 1])
          this.newGameData.push(response.data.offres[response.data.offres.length - 2])
          this.newGameData.push(response.data.offres[response.data.offres.length - 3])
          this.newGameData.push(response.data.offres[response.data.offres.length - 4])

        } else {
          this.newGameData = response.data.offres
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
  },

  methods: {
    getImg(img) {
      return "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + img
    },
    getDate(date) {
      return new Date(date).toLocaleString();

    },
  },
};
