import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: "boosterSection",
  components: {
    loadStripe,
  },
  data() {
    return {
      session_id: "",
      active: false,
      userData: {},
      comment: "",
      reviewCoach: [],
      coachRate: 0,
      myRate: 0,
      orderCoach: {
        hours: null,
        coach: "",
        user: ""

      }
    };
  },
  created() {
    axios
      .post(
        "https://eloboost-tn.herokuapp.com/api/user/getUserById",
        { user: this.$route.query.id }
      )
      .then((response) => {
        this.userData = response.data.user
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
    this.getComment()
    this.getRate()
  },
  methods: {
    modalShowUp() {
      this.active = true
    },
    preparePayment() {
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/stripe", {
          amount: this.userData.pricePerHour * this.orderCoach.hours, linkCancel: "https://eloboost.onrender.com/booster?id=" + this.$route.query.id,
          name: "Order Coach",
          message: "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page"
        }

        ).then((response) => {
          this.$toast.open({
            message: "Thank you, we will back to you as soon as possible",
            type: "success",
          });
          this.session_id = response.data.id
          return this.$stripe.redirectToCheckout({ sessionId: this.session_id });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during payment",
            type: "error",
          });
        })
    },
    coachOrder() {
      if (localStorage.getItem('userData')) {
        this.orderCoach.coach = localStorage.getItem('userData')
        this.orderCoach.user = this.$route.query.id
        let config = {
          headers: {
            authToken: localStorage.getItem("token"),
            refreshToken: localStorage.getItem("refreshToken")
          }
        }
        axios
          .post(
            "https://eloboost-tn.herokuapp.com/api/orderCoach", this.orderCoach, config
          )
          .then((response) => {
            localStorage.setItem("token", response.data.authToken)
            this.preparePayment()
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.$toast.open({
              message: "Error during sending message",
              type: "error",
            });
          })
      } else {
        this.$toast.open({
          message: "You need to sign In",
          type: "info",
        });
      }
    },
    // userRate(id) {
    //   console.log(id)
    //   axios
    //     .get(
    //       "https://eloboost-tn.herokuapp.com/api/rates/" + this.$route.query.id + "/" + id
    //     ).then((response) => {
    //       if (response) {
    //         return parseInt(response.data.rate)
    //       } else {
    //         return false
    //       }
    //     })

    // },
    getRate() {
      axios
        .get(
          "https://eloboost-tn.herokuapp.com/api/rates/coach/" + this.$route.query.id
        )
        .then((response) => {
          console.log("rate", response)
          if (response.data != null) {
            this.coachRate = response.data
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        })
    },
    sendRate() {
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/rates",
          {
            user: localStorage.getItem('userData'),
            coach: this.$route.query.id,
            rate: this.myRate
          }, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          console.log("rate", response)
        })
        .catch((error) => {
          console.error("There was an error!", error);
        })
    },
    getComment() {
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/avis/getReceiver", { receiverId: this.$route.query.id }
        )
        .then((response) => {
          console.log("review", response)
          this.reviewCoach = response.data.user
        })
        .catch((error) => {
          console.error("There was an error!", error);
        })
    },
    reviewOfUser(id) {
      if (localStorage.getItem("userData") == id) return true
      else return false
    },
    sendComment() {
      if (localStorage.getItem('userData')) {
        axios
          .post(
            "https://eloboost-tn.herokuapp.com/api/avis/add",
            { userId: localStorage.getItem('userData'), receiverId: this.$route.query.id, subject: this.comment }
          )
          .then((response) => {
            this.sendRate()
            this.getRate()
            // this.userData = response.data.user
            this.getComment()
            this.$toast.open({
              message: "Thank you, message send",
              type: "success",
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.$toast.open({
              message: "Error during sending message",
              type: "error",
            });
          })
      } else {
        this.$toast.open({
          message: "You need to sign In",
          type: "info",
        });
      }

    },
    deleteReview(id) {
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/avis/removeAvis",
          { avisId: id }
        )
        .then((response) => {
          // this.userData = response.data.user
          this.getComment()
          this.$toast.open({
            message: "Your comment is deleted successfully",
            type: "success",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during deleting comment",
            type: "error",
          });
        })
    },
    getDate(date) {
      return new Date(date).toLocaleString();

    },
    getImg(img) {
      if (img) {
        return "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + img
      } else {
        return "https://res.cloudinary.com/eloboost/image/upload/v1617048577/t%C3%A9l%C3%A9chargement_yo46pz_cse8su.jpg"
      }
    },
  }
};
