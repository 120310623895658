import axios from "axios";

export default {
  name: "blogSection",
  components: {},
  data() {
    return {
      blogData: []
    };
  },
  created() {
    axios
      .get(
        "https://eloboost-tn.herokuapp.com/api/article"
      )
      .then((response) => {
        console.log(response)
        if (response.data.articles.length > 3) {
          this.blogData.push(response.data.articles[response.data.articles.length - 1])
          this.blogData.push(response.data.articles[response.data.articles.length - 2])
          this.blogData.push(response.data.articles[response.data.articles.length - 3])
        } else {
          this.blogData = response.data.articles
        }

      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
  },

  methods: {
    getImg(img) {
      return "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + img
    },
    getDate(date) {
      return new Date(date).toLocaleString();

    },
    getLink(link) {
      return "/article?id=" + link
    }
  },
};
