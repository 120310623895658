import axios from "axios";

export default {
  name: "allBoostersSection",
  data() {
    return {
      homeCoachData: []
    };
  },
  created() {
    axios
      .get(
        "https://eloboost-tn.herokuapp.com/api/user/getAll"
      )
      .then((response) => {
        console.log("test", response)
        response.data.users.forEach(element => {
          if ((element.state == 3) || (element.state == 4)) {
            this.homeCoachData.push(element)
          }
        });
        console.log(this.homeCoachData)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
  },

  methods: {
    getLink(link) {
      return "/booster?id=" + link
    },
    getImg(img) {
      if (img) {
        return "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + img
      } else {
        return "https://res.cloudinary.com/eloboost/image/upload/v1617048577/t%C3%A9l%C3%A9chargement_yo46pz_cse8su.jpg"
      }
    },
  },
};
