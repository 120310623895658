import subHeroSection from "../../components/subHeroSection/index.vue";
import privacySection from "../../components/privacySection/index.vue";

export default {
  name: "faqPage",
  components: {
    subHeroSection,
    privacySection
  },
};
