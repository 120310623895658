import axios from "axios";

export default {
  name: "forgetPasswordSection",
  data() {
    return {
      email: ""
    };
  },
  methods: {
    forgetPasswordUser(e) {
      e.preventDefault()
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/recoverPasswordRequest",
          { email: this.email }
        )
        .then((response) => {
          console.log(response)
          this.$toast.open({
            message: "An email has been sent",
            type: "info",
          });
          this.email = ""
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during sending email",
            type: "error",
          });
        })
    }
  }
};
