import subHeroSection from "../../components/subHeroSection/index.vue";
import boostValoSection from "../../components/boostValoSection/index.vue";

export default {
  name: "boostPage",
  components: {
    subHeroSection,
    boostValoSection
  },
};
