import axios from "axios";
export default {
  name: "faqSection",
  data() {
    return {
    };
  },
  methods: {
  }
};
