import axios from "axios";

export default {
  name: "forgetPasswordSection",
  data() {
    return {
      passwordInput0: "password",
      passwordInput1: "password",
      passwordInput2: "password",
      iconPassword0: "fa fa-eye",
      iconPassword1: "fa fa-eye",
      iconPassword2: "fa fa-eye",
      confirmPassword: "",
      user: {
        userId: "",
        cpassword: "",
        password: ""
      }
    };
  },
  created() {
    if (!this.$router.history.current.query.email) {
      this.$router.replace("/");
    }
    var newHash = this.$router.history.current.query.hash.replace(/\s/g, '+');
    var payloadVerify = {
      email: this.$router.history.current.query.email,
      hash: newHash
    }
    console.log(payloadVerify, this.$router)
    axios
      .post(
        "https://eloboost-tn.herokuapp.com/api/user/verifyRecoverPasswordRequest", payloadVerify
      )
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log("hash probleme")
        this.errorMessage = error.message;
      });
  },
  methods: {
    switchVisibility(type) {
      if (type == 1) {
        this.passwordInput1 = this.passwordInput1 === "password" ? "text" : "password";
        this.iconPassword1 = this.iconPassword1 === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye"
      } else if (type == 2) {
        this.passwordInput2 = this.passwordInput2 === "password" ? "text" : "password";
        this.iconPassword2 = this.iconPassword2 === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye"
      } else {
        this.passwordInput0 = this.passwordInput0 === "password" ? "text" : "password";
        this.iconPassword0 = this.iconPassword0 === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye"
      }
    },
    forgetPasswordUser(e) {
      //   e.preventDefault()
      //   console.log(this.user)
      //   if (this.user.password == this.confirmPassword) {
      //     this.user.userId = this.$route.query.id
      //     axios
      //       .post(
      //         "https://eloboost-tn.herokuapp.com/api/user/recoverPassword",
      //         this.user
      //       )
      //       .then((response) => {
      //         console.log(response)
      //         this.$toast.open({
      //           message: "Your password has been changed.",
      //           type: "success",
      //         });
      //         this.confirmPassword = "";
      //         this.user.password = ""
      //         this.user.cpassword = ""
      //         this.$router.replace("/");
      //       })
      //       .catch((error) => {
      //         console.error("There was an error!", error);
      //         this.$toast.open({
      //           message: "Error during changing password",
      //           type: "error",
      //         });
      //       })
      //   } else {
      //     this.$toast.open({
      //       message: "Verify password",
      //       type: "info",
      //     });
      //   }



      var payload = {
        email: this.$router.history.current.query.email,
        hash: this.$router.history.current.query.hash.replace(/\s/g, '+'),
        password: this.password,
        confirmPassword: this.confirmPassword
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/recoverPassword", payload
        )
        .then((response) => {
          this.$toast.open({
            message: "Your password has been changed.",
            type: "success",
            duration: 5000
            // position: "top-right",
          });
          this.$router.push({ name: 'Home' })
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Error during changing password",
            type: "error",
            duration: 7000
            // position: "top-right",
          });
        });
    },
  }
}

