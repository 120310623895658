import subHeroSection from "../../components/subHeroSection/index.vue";
import faqSection from "../../components/faqSection/index.vue";

export default {
  name: "faqPage",
  components: {
    subHeroSection,
    faqSection
  },
};
