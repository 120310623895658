import subHeroSection from "../../components/subHeroSection/index.vue";
import recoverPasswordSection from "../../components/recoverPasswordSection/index.vue";

export default {
  name: "recoverPasswordPage",
  components: {
    subHeroSection,
    recoverPasswordSection
  },
};
