import subHeroSection from "../../components/subHeroSection/index.vue";
import forgetPasswordSection from "../../components/forgetPasswordSection/index.vue";

export default {
  name: "signUpPage",
  components: {
    subHeroSection,
    forgetPasswordSection
  },
};
