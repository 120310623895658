import subHeroSection from "../../components/subHeroSection/index.vue";
import contactSection from "../../components/contactSection/index.vue";

export default {
  name: "contactPage",
  components: {
    subHeroSection,
    contactSection
  },
};
