import axios from "axios";

export default {
  name: "articleSection",
  data() {
    return {
      blogDetail: {}
    };
  },
  created() {
    axios
      .get(
        "https://eloboost-tn.herokuapp.com/api/article/" + this.$route.query.id,
      )
      .then((response) => {
        console.log(response)
        this.blogDetail = response.data.articles
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
  },
  methods: {
    getImg(img) {
      return "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + img
    },
    getDate(date) {
      return new Date(date).toLocaleString();

    },
  }
};
