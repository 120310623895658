/* eslint-disable indent */
/* eslint-disable no-sparse-arrays */
/* eslint-disable comma-style */
/* eslint-disable quotes */
// eslint-disable-next-line semi
import axios from "axios";
import { VueEditor, Quill } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  name: "settingsSection",
  data() {
    return {
      region: [{
        fullName: 'Brazil',
        abbreviation: 'br1'
        },

          {
            fullName: 'Europe Nordic & East	',
            abbreviation: 'eun1'
          },
            {
              fullName: 'Europe West	',
              abbreviation: 'euw1'
            },
              {
                fullName: 'Japan',
                abbreviation: 'jp1'
              },
                {
                  fullName: 'Republic of Korea',
                  abbreviation: 'kr'
                },
                  {
                    fullName: 'Latin America North',
                    abbreviation: 'la1'
        },
        {
          fullName: 'Latin America South',
          abbreviation: 'la2'
        },
                    {
                      fullName: 'North',
                      abbreviation: 'na1'
                    },

                    {
                      fullName: 'Oceania',
                      abbreviation: 'oc1'
                    },

                    {
                      fullName: 'Turkey',
                      abbreviation: 'tr1'
                    },

                    {
                      fullName: 'Russia',
                      abbreviation: 'ru'
                    }
                  ],
      defaultToolbar: [
        [{ header: [false, 4] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      ],
      typeOfGame: "",
      confirmPassword: "",
      password: {
        cpassword: "",
        password: "",
        userId: localStorage.getItem("userData")
      },
      userToCoach: {
        _id: localStorage.getItem("userData"),
        userName: "",
        country: "",
        currentRank: "",
        maxRank: "",
        languages: "",
        discord: "",
        cv: "",
        photo: "",
        achivements: "",
        description: "",
        state: 0,
        moreInfo: "",
        demande: {
          state: "",
          name: "",
          opGgLink: "",
          mainLanes: "",
          mainChamp: "",
          lolchessGgg: "",
          mainAgents: "",
          trackerGg: ""
        }
      },
      // demande: [],
      user: {
        region: "",
        summoner: "",
        userName: "",
        email: "",
        _id: localStorage.getItem("userData"),
      }
    };
  },
  created() {
    this.userData()
  },
  methods: {
    buttonVerification() {
      
    },
    userData() {
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/getUser",
          { user: this.user._id }, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          // this.userToCoach = response.data.user
          this.userToCoach.photo = response.data.user.photo
          this.userToCoach.userName = response.data.user.userName
          this.userToCoach.country = response.data.user.country
          this.userToCoach.currentRank = response.data.user.currentRank
          this.userToCoach.maxRank = response.data.user.maxRank
          this.userToCoach.languages = response.data.user.languages
          this.userToCoach.discord = response.data.user.discord
          this.userToCoach.cv = response.data.user.cv
          this.userToCoach.achivements = response.data.user.achivements
          this.userToCoach.description = response.data.user.description
          this.userToCoach.state = response.data.user.state
          this.userToCoach.moreInfo = response.data.user.moreInfo
          this.user.userName = response.data.user.userName
          this.user.email = response.data.user.email
          this.user.region = response.data.user.region
          this.user.summoner = response.data.user.summoner
        })
        .catch((error) => {
          console.error("There was an error!", error);
        })
    },
    changeUserInfo(e) {
      e.preventDefault()
      console.log(this.userToCoach)
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/updateProfile",
          this.userToCoach, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          this.$toast.open({
            message: "Your information has been updated",
            type: "info",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error",
            type: "error",
          });
        })
    },
    openUploadModal(e) {
      e.preventDefault();
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "eloBoost", upload_preset: "we3x3upp" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.userToCoach.cv = this.publicId;
            }
          }
        )
        .open();
    },
    openUploadModalPicture(e) {
      e.preventDefault();
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "eloBoost", upload_preset: "we3x3upp" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.userToCoach.photo = this.publicId;
            }
          }
        )
        .open();
    },
    updateProfil(e) {
      e.preventDefault()
      console.log(this.user)
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/updateProfile",
          this.user, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          this.$toast.open({
            message: "Your account is updated",
            type: "info",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error during updating profil",
            type: "error",
          });
        })

    },
    updatePassword(e) {
      e.preventDefault();
      console.log(this.password)
      if (this.confirmPassword == this.password.password) {
        let config = {
          headers: {
            authToken: localStorage.getItem("token"),
            refreshToken: localStorage.getItem("refreshToken")
          }
        }
        axios
          .post(
            "https://eloboost-tn.herokuapp.com/api/user/changePassword",
            this.password, config
          )
          .then((response) => {
            localStorage.setItem("token", response.data.authToken)
            this.$toast.open({
              message: "Your account is updated",
              type: "info",
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.$toast.open({
              message: "Error during updating profil",
              type: "error",
            });
          })
      } else {
        this.$toast.open({
          message: "Error during updating profil",
          type: "error",
        });
      }


    },
    // getState(state) {
    //   if (state) {
    //     return state
    //   } else {
    //     return ""
    //   }
    // },
    // getDate(date) {
    //      return new Date(date).toLocaleString();

    // },
    // getRequestGame(type) {
    //   if (type) {
    //     return type
    //   } else {
    //     return ""
    //   }
    // }
    // ,
    // getRequestState(type) {
    //   if (type == 2) {
    //     return "booster"
    //   } else if (type == 2) {
    //     return "Coach"
    //   } else {
    //     return "booster & Coach"
    //   }
    // }
    // ,
    // getRequestStatus(type) {
    //   if (type == 1) {
    //     return "accepted"
    //   } else if (type == 2) {
    //     return "Refused"
    //   } else {
    //     return "Pending"
    //   }
    // }
  }
};
