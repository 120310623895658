import Vue from "vue";
import VueRouter from "vue-router";
import Navbar from "../views/navbar/index.vue";
import Home from "../views/homePage/index.vue";
import Contact from "../views/contactPage/index.vue";
import signUpPage from "../views/signUpPage/index.vue";
import signInPage from "../views/signInPage/index.vue";
import settingsPage from "../views/settingsPage/index.vue";
import profilePage from "../views/profilePage/index.vue";
import boosterPage from "../views/boosterPage/index.vue";
import boostTFTPage from "../views/boostTFTPage/index.vue";
import boostValoPage from "../views/boostValoPage/index.vue";
import boostLolPage from "../views/boostLolPage/index.vue";
import allBoostersPage from "../views/allBoostersPage/index.vue";
import faqPage from "../views/faqPage/index.vue";
import Article from "../views/articlePage/index.vue";
import jobPage from "../views/jobPage/index.vue";
import privacy from "../views/privacyPage/index.vue";
import forgetPassword from "../views/forgetPasswordPage/index.vue";
import recoverPassword from "../views/recoverPasswordPage/index.vue";


Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  const id = localStorage.getItem('userData');
  if (id) {
    next();
    return;
  }
  window.location.replace('/');
};

const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('userData')) {
    next();
    return;
  }
  window.location.replace('/');
};

const routes = [
  {
    path: "/",
    redirect: "Home",
    component: Navbar,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home
      },
      {
        path: "/jobs",
        name: "jobs",
        component: jobPage
      },
      {
        path: "/contact",
        name: "contact",
        component: Contact
      },
      {
        path: "/sign-up",
        name: "Sign-up",
        component: signUpPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "/sign-in",
        name: "Sign-in",
        component: signInPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "/forget-password",
        name: "Forget password",
        component: forgetPassword,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "/recover-password",
        name: "Recover password",
        component: recoverPassword,
        beforeEnter: ifNotAuthenticated,
      },

      {
        path: "/settings",
        name: "Settings",
        component: settingsPage,
        beforeEnter: ifAuthenticated,
      }
      ,
      {
        path: "/order",
        name: "Order",
        component: profilePage,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/booster",
        name: "Booster",
        component: boosterPage
      },
      {
        path: "/boost-lol",
        name: "Boost",
        component: boostLolPage
      },
      {
        path: "/boost-tft",
        name: "Boost",
        component: boostTFTPage
      }
      ,
      {
        path: "/boost-valorant",
        name: "Boost",
        component: boostValoPage
      },
      {
        path: "/boosters",
        name: "Boosters",
        component: allBoostersPage
      }
      , {
        path: "/faq",
        name: "FAQ",
        component: faqPage
      }
      , {
        path: "/privacy",
        name: "privacy",
        component: privacy
      }
      , {
        path: "/article",
        name: "Article",
        component: Article
      }
    ]
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
