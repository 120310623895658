import subHeroSection from "../../components/subHeroSection/index.vue";
import profileSection from "../../components/profileSection/index.vue";

export default {
  name: "profilePage",
  components: {
    subHeroSection,
    profileSection
  },
};
