import subHeroSection from "../../components/subHeroSection/index.vue";
import allBoostersSection from "../../components/allBoostersSection/index.vue";

export default {
  name: "allBoostersPage",
  components: {
    subHeroSection,
    allBoostersSection
  },
};
