
// import { Carousel, Slide } from "vue-carousel";
export default {
  name: "coachSection",
  components: {
    // Carousel,
    // Slide,
  },
  data() {
    return {};
  },
  created() {},

  methods: {},
};
