export default {
  name: "navbarSection",
  data() {
    return {
      active: false,
      connectedUser: localStorage.getItem('userData') || false,
      // user: {
      //   email: "",
      //   password: ""
      // }
    };
  },
  created() {
    if (this.$route.query.payment == "true") {
      this.$toast.open({
        message: "successful payment",
        type: 'success',
        duration: 5000
        // all of other options may go here
      });
    }
  },

  methods: {
    // forgetPassword() {
    //   this.$router.replace("/forget-password")
    // },
    signOut() {
      localStorage.removeItem("userData")
      this.$router.go()
    },

  },
};
