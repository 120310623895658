import subHeroSection from "../../components/subHeroSection/index.vue";
import boostTFTSection from "../../components/boostTFTSection/index.vue";

export default {
  name: "boostPage",
  components: {
    subHeroSection,
    boostTFTSection
  },
};
