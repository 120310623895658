import axios from "axios";

export default {
  name: "signUpSection",
  data() {
    return {
      passwordInput1: "password",
      iconPassword1: "fa fa-eye",
      user: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    switchVisibility() {
      this.passwordInput1 = this.passwordInput1 === "password" ? "text" : "password";
      this.iconPassword1 = this.iconPassword1 === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye"
    },
    signInUser(e) {
      e.preventDefault()
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/login",
          this.user
        )
        .then((response) => {
          console.log(response)
          localStorage.setItem("userData", response.data.user._id);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);

          this.$toast.open({
            message: "Welcome",
            type: "info",
          });
          this.$router.go()
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Wrong password or email",
            type: "error",
          });
        })

    }
  }
};
