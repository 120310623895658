import axios from "axios";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
export default {
  name: "jobSection",
  data() {
    return {
      rooms: [],
      messages: [],
      messagesloaded: false,
      roomId: null,
      otherUser: null,
      loadingRooms: true,
      showModal: false,
      activeImage: "",
      interval: null,
      typeOfGame: "",
      userToCoach: {
        _id: "",
        userName: "",
        country: "",
        currentRank: "",
        maxRank: "",
        languages: "",
        discord: "",
        cv: "",
        achivements: "",
        description: "",
        demande: {
          state: "",
          name: "",
          opGgLink: "",
          mainLanes: "",
          mainChamp: "",
          lolchessGgg: "",
          mainAgents: "",
          trackerGg: ""
        }
      },
      demande: [],
    };
  },
  components: {
    ChatWindow,
  },
  computed: {
    user() {
      return localStorage.getItem('userData')
    },
  },
  created() {
    this.userData()
  },
  methods: {
    getRoomInfo({ room }) {
      this.roomId = room.roomId;
      this.messagesloaded = false;
      this.getMessages(room.roomId);
    },

    getMessages(roomId) {
      try {
        if (roomId) {
          axios
            .get(
              "https://eloboost-tn.herokuapp.com/api/messages/room/" + roomId
            )
            .then((response) => {
              this.messages = response.data.messages
              console.log("get message", this.messages)

            })
            .catch((error) => {
              console.error("There was an error!", error.response.data);
            });
          this.messagesloaded = true;
        }
      } catch (error) {
        console.log(error);
        this.messagesloaded = false;
      }
    },

    async getRooms() {
      try {
        const res = await axios.get(
          "https://eloboost-tn.herokuapp.com/api/chatRooms/" +
          localStorage.getItem('userData')
        );
        this.loadingRooms = false;
        this.rooms = res.data.chatRooms.map((room) => ({
          ...room,
          users: this.getRoomUsers(room),
          roomId: room._id,
          roomName: this.getRoomName(room),
          avatar: this.getOtherUserAvatar(room),
        }));
      } catch (error) {
        console.log(error);
      }
    },

    getOtherUser(users) {
      console.log(users);
      return users.find((user) => user._id != localStorage.getItem('userData'));
    },

    getOtherUserAvatar({ users }) {
      const otherUser = this.getOtherUser(users);
      return !otherUser.photo
        ? "https://res.cloudinary.com/eloboost/image/upload/v1617048577/t%C3%A9l%C3%A9chargement_yo46pz_cse8su.jpg"
        : `https://res.cloudinary.com/eloboost/image/upload/v1617048577/${otherUser.photo}`;
    },

    getRoomName({ users }) {
      const otherUser = this.getOtherUser(users);
      return `${otherUser.userName || ""}`;
    },

    getRoomUsers({ users }) {
      return users.map((user) => ({
        username: `${user.userName} `,
        ...user,
      }));
    },

    async sendMessage(message) {
      console.log("message", message)
      try {
        message.sender_id = localStorage.getItem('userData');
        if (message.file) {
          message.file.url = await this.uploadFile(message.file);
        }
        const res = await axios.post("https://eloboost-tn.herokuapp.com/api/messages/", message)
        this.messages = [...this.messages, res.data.messageToSend];
        console.log("send message", this.messages)
      } catch (err) {
        console.log(err);
      }
    },

    async sendReaction({ roomId, messageId, reaction, remove }) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.reactions = message.reactions
        ? { ...message.reactions, [reaction.name]: [this.user] }
        : { [reaction.name]: [this.user] };
      try {
        await axios
          .put(
            "https://eloboost-tn.herokuapp.com/api/messages/message/" +
            messageId,
            { reactions: message.reactions }
          )
          .then((response) => { })
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });

      } catch (err) {
        console.log(err);
      }
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
      console.log(this.messages);
    },

    async editMessage({ messageId, newContent }) {
      try {
        await axios
          .put(
            "https://eloboost-tn.herokuapp.com/api/messages/message/" +
            messageId,
            { message: { content: newContent } }
          )
          .then((response) => {
            this.editLocalMessage(messageId, newContent);
          })
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });
        this.editLocalMessage(messageId, newContent);
      } catch (err) {
        console.log(err);
      }
    },


    openUploadModal(e) {
      e.preventDefault();
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "eloBoost", upload_preset: "we3x3upp" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.userToCoach.cv = this.publicId;
            }
          }
        )
        .open();
    },
    verifConnected() {
      if (localStorage.getItem("userData")) {
        return true
      } else {
        return false
      }
    },
    userData() {
      if (localStorage.getItem("userData")) {
        let config = {
          headers: {
            authToken: localStorage.getItem("token"),
            refreshToken: localStorage.getItem("refreshToken")
          }
        }
        this.userToCoach._id = localStorage.getItem("userData")
        axios
          .post(
            "https://eloboost-tn.herokuapp.com/api/user/getUser",
            { user: this.userToCoach._id }, config
          )
          .then((response) => {
            localStorage.setItem("token", response.data.authToken)
            console.log("tesst", response)
            this.demande = response.data.user.demande
            this.userToCoach.userName = response.data.user.userName
            this.userToCoach.country = response.data.user.country
            this.userToCoach.currentRank = response.data.user.currentRank
            this.userToCoach.maxRank = response.data.user.maxRank
            this.userToCoach.languages = response.data.user.languages
            this.userToCoach.discord = response.data.user.discord
            this.userToCoach.cv = response.data.user.cv
            this.userToCoach.achivements = response.data.user.achivements
            this.userToCoach.description = response.data.user.description
            this.user.userName = response.data.user.userName
            this.user.email = response.data.user.email
          })
          .catch((error) => {
            console.error("There was an error!", error);
          })
      }
    },
    changeUserStat(e) {
      e.preventDefault()
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      if (localStorage.getItem("userData")) {
        this.userToCoach._id = localStorage.getItem("userData")
        console.log(this.userToCoach);
        axios
          .post(
            "https://eloboost-tn.herokuapp.com/api/user/updateProfile",
            this.userToCoach, config
          )
          .then((response) => {
            localStorage.setItem("token", response.data.authToken)
            console.log(response)
            this.$toast.open({
              message: "We will back to you as soon as possible",
              type: "info",
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.$toast.open({
              message: "Error",
              type: "error",
            });
          })
      }
    },
    getDate(date) {
      return new Date(date).toLocaleString();
    },
    getRequestGame(type) {
      if (type) {
        return type
      } else {
        return ""
      }
    }
    ,
    getRequestState(type) {
      if (type == 2) {
        return "booster"
      } else if (type == 2) {
        return "Coach"
      } else {
        return "booster & Coach"
      }
    }
    ,
    getRequestStatus(type) {
      if (type == 1) {
        return "accepted"
      } else if (type == 2) {
        return "Refused"
      } else {
        return "Pending"
      }
    }
  },
  async mounted() {
    await this.getRooms();
    this.interval = setInterval(() => {
      this.getMessages(this.roomId);
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
