import { Carousel, Slide } from "vue-carousel";

export default {
  name: "heroSection",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
    };
  },
  created() {

  },

  methods: {
  },
};
