import axios from "axios";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import champions from 'lol-champions'

export default {
  name: "profileSection",
  data() {
    return {
      matches: [],
      userData: {},
      rooms: [],
      messages: [],
      messagesloaded: false,
      roomId: null,
      otherUser: null,
      loadingRooms: true,
      showModal: false,
      activeImage: "",
      interval: null,
      order: {},
      orderCoach: {},
      userLeagueData: {
        summoner: "",
        region: "",
        _id: ""
      }
    };
  },
  components: {
    ChatWindow,
  },
  computed: {
    user() {
      return localStorage.getItem('userData')
    },
  },
  methods: {
    getRoomInfo({ room }) {
      this.roomId = room.roomId;
      this.messagesloaded = false;
      this.getMessages(room.roomId);
    },

    getMessages(roomId) {
      try {
        if (roomId) {
          axios
            .get(
              "https://eloboost-tn.herokuapp.com/api/messages/room/" + roomId
            )
            .then((response) => {
              this.messages = response.data.messages
              console.log("get message", this.messages)

            })
            .catch((error) => {
              console.error("There was an error!", error.response.data);
            });
          this.messagesloaded = true;
        }
      } catch (error) {
        console.log(error);
        this.messagesloaded = false;
      }
    },

    async getRooms() {
      try {
        const res = await axios.get(
          "https://eloboost-tn.herokuapp.com/api/chatRooms/" +
          localStorage.getItem('userData')
        );
        this.loadingRooms = false;
        this.rooms = res.data.chatRooms.map((room) => ({
          ...room,
          users: this.getRoomUsers(room),
          roomId: room._id,
          roomName: this.getRoomName(room),
          avatar: this.getOtherUserAvatar(room),
        }));
      } catch (error) {
        console.log(error);
      }
    },

    getOtherUser(users) {
      console.log(users);
      return users.find((user) => user._id != localStorage.getItem('userData'));
    },

    getOtherUserAvatar({ users }) {
      const otherUser = this.getOtherUser(users);
      return !otherUser.photo
        ? "https://res.cloudinary.com/eloboost/image/upload/v1617048577/t%C3%A9l%C3%A9chargement_yo46pz_cse8su.jpg"
        : `https://res.cloudinary.com/eloboost/image/upload/v1617048577/${otherUser.photo}`;
    },

    getRoomName({ users }) {
      const otherUser = this.getOtherUser(users);
      return `${otherUser.userName || ""}`;
    },

    getRoomUsers({ users }) {
      return users.map((user) => ({
        username: `${user.userName} `,
        ...user,
      }));
    },

    async sendMessage(message) {
      console.log("message", message)
      try {
        message.sender_id = localStorage.getItem('userData');
        if (message.file) {
          message.file.url = await this.uploadFile(message.file);
        }
        const res = await axios.post("https://eloboost-tn.herokuapp.com/api/messages/", message)
        this.messages = [...this.messages, res.data.messageToSend];
        console.log("send message", this.messages)
      } catch (err) {
        console.log(err);
      }
    },

    async sendReaction({ roomId, messageId, reaction, remove }) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.reactions = message.reactions
        ? { ...message.reactions, [reaction.name]: [this.user] }
        : { [reaction.name]: [this.user] };
      try {
        await axios
          .put(
            "https://eloboost-tn.herokuapp.com/api/messages/message/" +
            messageId,
            { reactions: message.reactions }
          )
          .then((response) => { })
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });

      } catch (err) {
        console.log(err);
      }
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
      console.log(this.messages);
    },

    async editMessage({ messageId, newContent }) {
      try {
        await axios
          .put(
            "https://eloboost-tn.herokuapp.com/api/messages/message/" +
            messageId,
            { message: { content: newContent } }
          )
          .then((response) => {
            this.editLocalMessage(messageId, newContent);
          })
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });
        this.editLocalMessage(messageId, newContent);
      } catch (err) {
        console.log(err);
      }
    },



    getState(arg) {
      if (arg == 1) {
        return 'accepted'
      } else if (arg == 0) {
        return 'In progress'
      } else {
        return 'Refused'
      }
    },
    getCoachOrder() {
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .get(
          "https://eloboost-tn.herokuapp.com/api/orderCoach/" +
          localStorage.getItem('userData'), config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          this.orderCoach = response.data.orders[response.data.orders.length - 1]
          console.log("orders coach", this.orderCoach)

        })
        .catch((error) => {
          console.error("There was an error!", error.response.data);
        });
    },
    getOrder() {
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .get(
          "https://eloboost-tn.herokuapp.com/api/order/" +
          localStorage.getItem('userData'), config
        )
        .then((response) => {
          console.log("orders123456", response)
          localStorage.setItem("token", response.data.authToken)
          this.order = response.data.orders[response.data.orders.length - 1]
          this.userData = response.data.orders[response.data.orders.length - 1].user

        })
        .catch((error) => {
          console.error("There was an error!", error.response.data);
        });
    },
    getImg(img) {
      switch (img) {
        case "Iron1":
          return "rankBadges/Season_2019_-_Iron_1.png"
          break;
        case "Iron2":
          return "rankBadges/Season_2019_-_Iron_2.png"
          break;
        case "Iron3":
          return "rankBadges/Season_2019_-_Iron_3.png"
          break;
        case "Iron4":
          return "rankBadges/Season_2019_-_Iron_4.png"
          break;

        case "Bronze1":
          return "rankBadges/Season_2019_-_Bronze_1.png"
          break;
        case "Bronze2":
          return "rankBadges/Season_2019_-_Bronze_2.png"
          break;
        case "Bronze3":
          return "rankBadges/Season_2019_-_Bronze_3.png"
          break;
        case "Bronze4":
          return "rankBadges/Season_2019_-_Bronze_4.png"
          break;

        case "Silver1":
          return "rankBadges/Season_2019_-_Sliver_1.png"
          break;
        case "Silver2":
          return "rankBadges/Season_2019_-_Sliver_2.png"
          break;
        case "Silver3":
          return "rankBadges/Season_2019_-_Sliver_3.png"
          break;
        case "Silver4":
          return "rankBadges/Season_2019_-_Sliver_4.png"
          break;

        case "Gold1":
          return "rankBadges/Season_2019_-_Gold_1.png"
          break;
        case "Gold2":
          return "rankBadges/Season_2019_-_Gold_2.png"
          break;
        case "Gold3":
          return "rankBadges/Season_2019_-_Gold_3.png"
          break;
        case "Gold4":
          return "rankBadges/Season_2019_-_Gold_4.png"
          break;


        case "Platinum1":
          return "rankBadges/Season_2019_-_Platinum_1.png"
          break;
        case "Platinum2":
          return "rankBadges/Season_2019_-_Platinum_2.png"
          break;
        case "Platinum3":
          return "rankBadges/Season_2019_-_Platinum_3.png"
          break;
        case "Platinum4":
          return "rankBadges/Season_2019_-_Platinum_4.png"
          break;



        case "Diamond1":
          return "rankBadges/Season_2019_-_Diamond_1.png"
          break;
        case "Diamond2":
          return "rankBadges/Season_2019_-_Diamond_2.png"
          break;
        case "Diamond3":
          return "rankBadges/Season_2019_-_Diamond_3.png"
          break;
        case "Diamond4":
          return "rankBadges/Season_2019_-_Diamond_4.png"
          break;



        case "Master":
          return "rankBadges/Season_2019_-_Master_1.png"
          break;

        case "Grandmaster":
          return "rankBadges/Season_2019_-_Grandmaster_2.png"
          break;

        case "Challenger":
          return "rankBadges/Season_2019_-_Challenger_3.png"
          break;


        default:
          return "rankBadges/Season_2019_-_Iron_4.png"
      }

    },
    getImgCoach(user) {
      if (user) {
        if (user.photo) {
          return "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + user.photo
        } else {
          return "https://res.cloudinary.com/eloboost/image/upload/v1617048577/t%C3%A9l%C3%A9chargement_yo46pz_cse8su.jpg"
        }
      }
    },
    getDate(date) {
      return new Date(date).toLocaleString();
    },
    getImage(item) {
      if (item) {
        return "http://ddragon.leagueoflegends.com/cdn/11.9.1/img/item/" + item + ".png"
      }
    },
    updateUserData() {
      this.userLeagueData._id = localStorage.getItem('userData')
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/updateProfile",
          this.userLeagueData, config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken)
          this.userData.summoner = this.userLeagueData.summoner
          this.userData.region = this.userLeagueData.region
          this.verifHistory()
          this.getHistory("isOk")
        })
        .catch((error) => {
          console.error("There was an error!", error.response);
        });
    },
    verifHistory() {
      if (!this.userData.summoner && !this.userData.region) {
        return false
      } else {
        return true
      }
    },

    getGameResult(arg) {
      if (arg == true) return "Victory"
      else return "Defeat"
    },
    getHistory(arg) {
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken")
        }
      }
      if (this.order.gameName == "tft" || this.order.gameName == "lol") {
        if (!this.userLeagueData.summoner || !this.userLeagueData.region) {
          this.userLeagueData.summoner = this.userData.summoner
          this.userLeagueData.region = this.userData.region
        }
        if (this.order.gameName == "lol") {
          axios
            .post(
              "https://eloboost-tn.herokuapp.com/api/match/league",
              this.userLeagueData, config
            )
            .then((response) => {
              localStorage.setItem("token", response.data.authToken)
              this.matches = []
              var selectedData
              if (response.data.matches) {
                response.data.matches.forEach(element => {
                  // if (new Date(this.order.createdAt) < new Date(element.gameCreation)) {
                  selectedData = champions.find(item => item.key == element.champion);
                  this.matches.push({
                    nameChamp: selectedData.name,
                    image: selectedData.icon,
                    date: element.gameCreation,
                    win: element.win,
                    gameMode: element.gameMode,
                    stats: element.kills + " / " + element.deaths + " / " + element.assist,
                    items: element.items
                  })
                  // }
                })
              } else {
                console.log("error", this.matches)
              }
            })
            .catch((error) => {
              console.error("There was an error history!", error.response);
            });
        } else if (this.order.gameName == "tft") {
          axios
            .post(
              "https://eloboost-tn.herokuapp.com/api/match/tft",
              this.userLeagueData, config
            )
            .then((response) => {
              localStorage.setItem("token", response.data.authToken)
              this.matches = []
              console.log(response.data.matches.items)
              if (response.data.matches) {
                response.data.matches.items.forEach(element => {
                  this.matches.push({
                    win: element.matches[0].metadata.result.displayValue,
                    champs: element.matches[0].units,
                    // participants: element.matches[0].participants,
                    damage: element.matches[0].stats.totalDamageToPlayers.displayValue,
                    date: element.metadata.startDate.displayValue
                  })
                  // }
                })
              } else {
                console.log("error", this.matches)
              }
            })
            .catch((error) => {
              console.error("There was an error history!", error.response);
            });
        }

      } else if (this.order.gameName == "valorant") {
        // this.$toast.open({
        //   message: "Can't get valorant history",
        //   type: "info",
        // });
      }

    }
  },
  async mounted() {
    await this.getOrder();
    await this.getCoachOrder();
    setTimeout(async () => {
      await this.getHistory()
    }, 500);
    await this.getRooms();
    this.interval = setInterval(() => {
      this.getMessages(this.roomId);
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
