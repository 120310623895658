import subHeroSection from "../../components/subHeroSection/index.vue";
import signUpSection from "../../components/signUpSection/index.vue";

export default {
  name: "signUpPage",
  components: {
    subHeroSection,
    signUpSection
  },
};
